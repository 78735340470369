import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed, AESEncryptDecryptService } from '@core';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../i18n/i18n.service';
import { LoadingService, SharedCartItemCountService } from '@shared';
import { RandomTokenService } from '../@api';
import { Globals } from '../globals';
import { SharedShopInfoService } from '@shared';
declare const dataLayer: any;
const log = new Logger('Login');

@UntilDestroy()
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	//version: string | null = environment.version;
	error: string | undefined;
	loginForm!: FormGroup;
	//isLoading: boolean = false;
	isShowPass: boolean = false;
	output: any;
	csrfToken: string;
	apiUsername: string;
	isEnableLogin: boolean = false;
	isLoginFail: boolean = false;
	isChecked: boolean = false;
	isLoggedin: boolean = false;
	isRemember: boolean = false;
	isRedirecting: boolean = false;
	rememberUsername: string;
	isClick: boolean = false;
	recaptchaV2SiteKey: string;
	captchaResponse: string;
	shopData: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private i18nService: I18nService,
		private credentialsService: CredentialsService,
		private AESEncryptDecrypt: AESEncryptDecryptService,
		private loadingService: LoadingService,
		private sharedCartItemCountService: SharedCartItemCountService,
		private randomTokenService: RandomTokenService,
		private sharedShopInfoService: SharedShopInfoService,
		private globals: Globals
	) {
		this.recaptchaV2SiteKey = globals.recaptchaV2SiteKey;
		//this.randomToken();
	}

	ngOnInit() {
		this.getShopInfo();
		var rememberMe = this.AESEncryptDecrypt.decryptRemeberMe(localStorage.getItem('rememberMe'));
		this.rememberUsername = rememberMe == false ? null : rememberMe;
		this.isRemember = this.rememberUsername != null ? true : false;
		if (!this.credentialsService.isAuthenticated()) {
			this.createForm();
		}

		/*if (this.credentialsService.credentials) {
			this.credentialsService
				.validateUserToken(this.credentialsService.credentials.token)
				.pipe(
					finalize(() => {
						this.isLoading = false;
					})
				)
				.subscribe((response: any) => {
					if (response.is_success == true) this.router.navigate(['/home'], { replaceUrl: true });
				});
		}*/

		/*this.authenticationService
			.getToken()
			.pipe(
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe((response: any) => {
				log.info(response);
				if (response.is_success) {
					this.isLoading = true;
					this.isEnableLogin = true;
				}
			});*/
	}
	public get cartItemCount(): string {
		return this.sharedCartItemCountService.getCount();
	}
	// convenience getter for easy access to form fields
	//get f() { return this.loginForm.controls; }
	login(token: string, recapcha: string) {
		this.isClick = true;
		this.isLoginFail = false;
		var isPromoCart = parseInt(this.cartItemCount) > 0 ? true : false;
		const login$ = this.authenticationService.login(
			this.loginForm.value,
			this.i18nService.language,
			isPromoCart,
			token,
			recapcha
		);
		login$
			.pipe(
				finalize(() => {
					this.loginForm.markAsPristine();
					//this.isLoading = false;
				}),
				untilDestroyed(this)
			)
			.subscribe(
				(response) => {
					if (response.is_success == true) {
						dataLayer.push({ ecommerce: null });
						dataLayer.push({
							event: 'login',
							ecommerce: {},
						});
						this.isRedirecting = true;
						var decryptRedirectTo = this.AESEncryptDecrypt.decryptParam(
							this.route.snapshot.queryParams.redirect
						);
						if (decryptRedirectTo != null) {
							this.router.navigate([decryptRedirectTo || '/'], { replaceUrl: true });
						} else {
							this.router.navigate(['/']);
						}

						//this.router.navigate([this.returnUrl]);
					} else {
						//log.debug(result);
						this.isClick = false;
						this.loginForm.controls['password'].reset();
						this.loginForm.controls['recaptcha'].reset();
						this.isLoginFail = true;
					}
				},
				(error) => {
					//log.debug(error);
					//this.toastError(this.translate.instant('error'), error.name);
				}
			);
	}

	togglePassword() {
		this.isShowPass = !this.isShowPass;
	}

	submitForm() {
		this.doLogin();
	}

	doLogin() {
		this.randomTokenService.getToken().subscribe((csrfToken: string) => {
			this.login(csrfToken, this.captchaResponse);
		});
	}

	checkUserToken() {
		this.credentialsService
			.validateUserToken()
			.pipe(
				finalize(() => {
					this.isChecked = true;
					this.isEnableLogin = true;
				})
			)
			.subscribe(
				(response: any) => {},
				(error) => {
					console.log(error);
					//this.toastError(this.translate.instant('error'), error.name);
				}
			);
	}
	public get isLoading(): boolean {
		return this.loadingService.getIsLoading();
	}
	public get authenticated(): boolean {
		return this.credentialsService.isAuthenticated();
	}
	logout() {
		this.randomTokenService.getToken().subscribe((token: string) => {
			this.doLogout(token);
		});
	}
	doLogout(token: string) {
		const logout$ = this.authenticationService.logout(token);
		logout$
			.pipe(
				finalize(() => {
					//this.isLoading = false;
				})
			)
			.subscribe(
				(result) => {
					if (result.is_success == true) {
						this.router.navigate(['/login']);
					}
				},
				(error) => {
					//log.debug(error);
					//this.toastError(this.translate.instant('error'), error.name);
				}
			);
	}

	createForm() {
		this.loginForm = this.formBuilder.group({
			username: [this.rememberUsername, Validators.required],
			password: ['', Validators.required],
			isRemember: [this.isRemember],
			recaptcha: ['', Validators.required],
		});

		/*this.loginForm.valueChanges.subscribe(data => {
			console.log('Form changes', data)
		})*/
	}

	resolved(captchaResponse: string) {
		this.captchaResponse = captchaResponse;
	}

	@HostBinding('style.--main') private cssVarMain: string;
	getShopInfo() {
		this.sharedShopInfoService.shopInfo.subscribe((data: any) => {
			this.shopData = data?.information;
			if (this.shopData) {
				//color
				this.cssVarMain = this.shopData.theme['theme_color'];
			}
		});
	}
}

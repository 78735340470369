import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from './globals';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { ShopInfoService } from './@api';
import {
	SharedModule,
	SharedUsernameService,
	SharedAuthService,
	ShopHeaderService,
	SharedShopInfoService,
	SharedAccessTokenService,
	SharedCartItemCountService,
} from '@shared';
import { AuthModule } from '@app/auth';
import { HomeModule } from './home/home.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RECAPTCHA_LANGUAGE, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShopHeaderComponent } from './shop-header/shop-header.component';
import { ShopFooterComponent } from './shop-footer/shop-footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CookieService } from 'ngx-cookie-service';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafeHtmlPipe } from './@shared/safeHtml.pipe';
import { SharedPipeModule } from './@shared/safeHtmlPipe.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import {
	OwlDateTimeModule,
	OwlNativeDateTimeModule,
	OWL_DATE_TIME_LOCALE,
	OWL_DATE_TIME_FORMATS,
} from 'ng-pick-datetime';

//import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { RefreshTokenInterceptor } from './refresh-token-interceptor';
import { JWT_OPTIONS, JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { AuthenticationService } from './auth/authentication.service';
export const MY_NATIVE_FORMATS = {
	datePickerInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
};
// Import the library
import { NgxStripeModule } from 'ngx-stripe';

import { ImagesetPipe } from '@shared/pipe/imageset.pipe';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { SwiperModule } from 'swiper/angular';
import panzoom from 'panzoom';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { QRCodeModule } from 'angularx-qrcode';
const socketConfig: SocketIoConfig = {
	url: environment.socketUrl,
	options: { transports: ['websocket', 'polling', 'flashsocket'] },
};

function jwtOptionsFactory(authenticationService: AuthenticationService) {
	return {
		tokenGetter: () => {
			return authenticationService.getAccessToken();
		},
	};
}
@NgModule({
	imports: [
		BrowserModule,
		ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
		FormsModule,
		HttpClientModule,
		HttpClientXsrfModule,
		TranslateModule.forRoot(),
		NgbModule,
		CoreModule,
		CommonModule,
		SharedModule,
		HomeModule,
		AuthModule,
		DragDropModule,
		RouterModule,
		ReactiveFormsModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		SharedPipeModule,
		SimpleModalModule,
		SwiperModule,
		NgxPaginationModule,
		NgxPaginationModule,
		HammerModule,
		CrystalLightboxModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		QRCodeModule,
		GalleryModule.forRoot(),
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: jwtOptionsFactory,
				deps: [AuthenticationService],
			},
		}),
		BrowserAnimationsModule, // required animations module
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		SocketIoModule.forRoot(socketConfig),
		AppRoutingModule, // must be imported as the last module as it contains the fallback route
		NgxStripeModule.forRoot(),
	],
	declarations: [
		AppComponent,
		ShopHeaderComponent,
		ShopFooterComponent,
		PageNotFoundComponent,
		ConfirmDialogComponent,
		CommonDialogComponent,
		ImagesetPipe,
		//SafeHtmlPipe,
	],
	providers: [
		SharedUsernameService,
		SharedAuthService,
		ShopHeaderService,
		SharedShopInfoService,
		SharedAccessTokenService,
		SharedCartItemCountService,
		CookieService,
		{
			provide: RECAPTCHA_LANGUAGE,
			useValue: 'ja',
		},
		Globals,
		{
			provide: OWL_DATE_TIME_LOCALE,
			useValue: 'ja-JP',
		},
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
		JwtInterceptor, // Providing JwtInterceptor allow to inject JwtInterceptor manually into RefreshTokenInterceptor
		{
			provide: HTTP_INTERCEPTORS,
			useExisting: JwtInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RefreshTokenInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

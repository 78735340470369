import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterByCategory',
	pure: false,
})
export class ImagesetPipe implements PipeTransform {
	transform(items: any[], selectedCategoryID: any): any {
		if (!items || !selectedCategoryID || selectedCategoryID == 'null') {
			return items;
		}
		// filter items array, items which match and return true will be
		// kept, false will be filtered out
		return items.filter((item) => item.category_id == selectedCategoryID);
	}
}

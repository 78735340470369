import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	getCheckout: (p: checkoutParams) =>
		`/manage/api/v1/checkout-process?payment_type=${p.payment_type}&address_id=${p.address_id}&operation_code=${p.operation_code}&address_type=${p.address_type}`,
};

export interface checkoutParams {
	address_id?: string;
	payment_type: number;
	operation_code: number;
	address_type: string;
}

@Injectable({
	providedIn: 'root',
})
export class CheckoutService {
	api_username: string;
	requestOptions;

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getCheckoutProcess(params: checkoutParams) {
		return this.httpClient.get<any>(routes.getCheckout(params), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('APIエラー'))
		);
	}
}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private msg = new BehaviorSubject({});
	loadMsg = this.msg.asObservable();

	//private isLoading = new BehaviorSubject(false);
	private _isLoading: boolean | null = null;
	//showIsLoading = this.isLoading.asObservable();

	constructor() {}

	/*getIsLoading(): boolean {
		return !!this.showIsLoading;
	}*/
	/**
	 * Checks is the user is authenticated.
	 * @return True if the user is authenticated.
	 */
	getIsLoading(): boolean {
		return !!this.loading;
	}

	/**
	 * Gets the user credentials.
	 * @return The user credentials or null if the user is not authenticated.
	 */
	get loading(): boolean | null {
		return this._isLoading;
	}

	setIsLoading(val: boolean = false) {
		this._isLoading = val;
	}
	/*setIsLoading(isLoading: boolean) {
		console.log("ppp"+isLoading);
		this.isLoading.next(isLoading);
	}*/

	setLoadingMsg(msg: string, type = 0) {
		this.msg.next({ msg: msg, type: type });
	}
}

import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

//import * as CryptoES from 'crypto-js';
import CryptoES from 'crypto-es';

@Injectable({
	providedIn: 'root',
})
export class AESEncryptDecryptService {
	secretKey = '424868526b5038796d7a4d4b31556742';
	secretKeyUrl = '586b696d66674e79395249494e507a66';
	iv = '6d7a4d4b31556742424868526b503879';

	// 決済
	checkoutKey = '4f4c4c6964787734767078324a746355';
	checkoutIv = '503742424939504b3670734b6b62346b';

	constructor() {}

	encrypt(msg: string): string {
		let randomHex = this.genHexString();
		let today = new Date();

		today.setSeconds(today.getSeconds() + 150);
		var jpTime = today.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' });

		let expire = formatDate(jpTime, 'yyyy-MM-dd HH:mm:ss', 'en', 'JST');
		let key = CryptoES.enc.Hex.parse(this.secretKey);
		let iv = CryptoES.enc.Hex.parse(randomHex);
		let encrypted = CryptoES.AES.encrypt(msg, key, { iv }).toString();
		//console.log(randomHex);
		//console.log(encrypted);
		return btoa(randomHex + expire + encrypted);
	}

	genHexString() {
		return [...Array(32)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
	}

	encryptRemeberMe(msg: string): string {
		let key = CryptoES.enc.Hex.parse(this.secretKeyUrl);
		let iv = CryptoES.enc.Hex.parse(this.iv);

		let encrypted = CryptoES.AES.encrypt(msg, key, { iv }).toString();

		return btoa(encrypted);
	}

	decryptRemeberMe(textToDecrypt: string) {
		let key = CryptoES.enc.Hex.parse(this.secretKeyUrl);
		let iv = CryptoES.enc.Hex.parse(this.iv);

		try {
			return CryptoES.AES.decrypt(atob(textToDecrypt), key, { iv }).toString(CryptoES.enc.Utf8);
		} catch (e) {
			return false;
		}
	}

	encryptParam(msg: string): string {
		let randomHex = this.genHexString();
		localStorage.setItem('iran', randomHex);
		let key = CryptoES.enc.Hex.parse(this.secretKeyUrl);
		let iv = CryptoES.enc.Hex.parse(randomHex);

		let encrypted = CryptoES.AES.encrypt(msg, key, { iv }).toString();

		return btoa(encrypted);
	}

	decryptParam(textToDecrypt: string) {
		if (localStorage.getItem('iran') == null) return false;
		let key = CryptoES.enc.Hex.parse(this.secretKeyUrl);
		let iv = CryptoES.enc.Hex.parse(localStorage.getItem('iran'));
		try {
			return CryptoES.AES.decrypt(atob(textToDecrypt), key, { iv }).toString(CryptoES.enc.Utf8);
		} catch (e) {
			return false;
		}
	}

	decryptPaymentKey(encryptedKey: string) {
		let key = CryptoES.enc.Hex.parse(this.checkoutKey);
		let iv = CryptoES.enc.Hex.parse(this.checkoutIv);
		try {
			return CryptoES.AES.decrypt(atob(encryptedKey), key, { iv }).toString(CryptoES.enc.Utf8);
		} catch (e) {
			return null;
		}
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';
import { I18nService } from '../i18n/i18n.service';

const routes = {
	postData: () => `/manage/api/v1/inquiry`,
};

export interface contactParams {
	//api_username: string;
	name: string;
	email: string;
	tel?: string;
	inquiry_type: number;
	order_no?: string;
	message: string;
}

@Injectable({
	providedIn: 'root',
})
export class ContactService {
	private contactForm = new BehaviorSubject<any>([]);
	contactInputValue = this.contactForm.asObservable();
	api_username: string;

	constructor(
		private httpClient: HttpClient,
		private i18nService: I18nService,
		private sharedUsernameService: SharedUsernameService
	) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
	}

	saveContactInput(data: any) {
		this.contactForm.next(data);
	}

	sendInquiry(token: string, recaptcha: string) {
		const postParams = new FormData();
		postParams.append('name', this.contactForm.value.name);
		postParams.append('email', this.contactForm.value.email);
		postParams.append('tel', this.contactForm.value.tel);
		postParams.append('inquiry_type', this.contactForm.value.inquiry_type);
		postParams.append('order_no', this.contactForm.value.order_no);
		postParams.append('message', this.contactForm.value.message);
		postParams.append('lang_code', this.i18nService.language);
		//postParams.append('api_username', this.sharedUsernameService.generateUsername());

		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
			Recaptcha: recaptcha,
		});
		/*postParams.forEach((value, key) => {
			console.log(key + ' ' + value);
		});*/
		return this.httpClient
			.post<any>(routes.postData(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@app/i18n';
//import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { SharedModule } from '../@shared/shared.module';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		I18nModule,
		SharedModule,
		RouterModule,
		RecaptchaModule,
		RecaptchaFormsModule,
	],
	declarations: [LoginComponent],
})
export class AuthModule {}

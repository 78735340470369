<div class="text-xs-center">
	<div class="loader-container">
		<div class="item">
			<i class="loader --cube"></i>
		</div>

		<div class="loading-text">
			<span>{{ message }}</span>
		</div>
	</div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { I18nService } from '../i18n/i18n.service';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService, SharedAccessTokenService } from '@shared';
import { CredentialsService } from '@app/auth';
const routes = {
	getdata: () => `/manage/api/v1/customer`,
	putData: () => `/manage/api/v1/customer`,
	passReset: () => `/manage/api/v1/password-reset`,
	activateCode: (p: string) => `/manage/api/v1/password-reset?activation_code=${p}`,
	unlockAcc: () => `/manage/api/v1/unlock-customer-account`,
};

export interface resignCustomerParams {
	operation_code: string;
	//id: string;
	password: string;
	lang_code: string;
	//api_username: 	string,
}

export interface passwordUpdateParams {
	operation_code: string;
	email: string;
	birth_date: string;
	lang_code: string;
	//api_username: 	string,
}

export interface passwordChangeParams {
	email: string;
	password: string;
	customer_id: string;
	operation_code: string;
	activation_code: string;
}

export interface unlockAccParams {
	email: string;
	birth_date: string;
	lang_code: string;
}

export interface putCustomerParams {
	//id: string;
	email: string;
	password: string;
	new_password?: string;
	first_name: string;
	last_name: string;
	first_name_kana: string;
	last_name_kana: string;
	is_get_magazine: string;
	//company_name: string;
	gender: string;
	job_id: string;
	lang_code: string;
	operation_code: string;
}

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	private customerStatus = new BehaviorSubject(true);
	customerExist = this.customerStatus.asObservable();
	api_username: string;
	requestOptions: any;

	constructor(
		private httpClient: HttpClient,
		private sharedUsernameService: SharedUsernameService,
		private i18nService: I18nService,
		private sharedAccessTokenService: SharedAccessTokenService,
		private credentialsService: CredentialsService
	) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getCustomer() {
		return this.httpClient.get<any>(routes.getdata(), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('APIエラー'))
		);
	}

	updateCustomer(params: putCustomerParams) {
		//var apiUsername = this.sharedUsernameService.generateUsername();

		const putParams = params;
		putParams['lang_code'] = this.i18nService.language;
		//putParams['api_username'] = apiUsername;

		return this.httpClient.put<any>(routes.putData(), JSON.stringify(putParams), this.requestOptions).pipe(
			map((response: any) => {
				if (response.is_success) {
					this.sharedAccessTokenService.setAccessToken(response.result_data.access_token);
					this.credentialsService.setCredentials(true);
				}
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}

	resign(params: resignCustomerParams) {
		//var apiUsername = this.sharedUsernameService.generateUsername();

		const requestOptions = {
			withCredentials: true,
		};
		const putParams = params;
		//putParams['api_username'] = apiUsername;

		return this.httpClient.put<any>(routes.putData(), JSON.stringify(putParams), this.requestOptions).pipe(
			map((response) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}

	customerLeave() {
		this.customerStatus.next(false);
		this.sharedAccessTokenService.setAccessToken(null);
		this.credentialsService.setCredentials(false);
		localStorage.removeItem('rememberMe');
		localStorage.removeItem('isShowGuide');
	}

	mailValidation(params: passwordUpdateParams, token: string, recaptcha: string) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
			Recaptcha: recaptcha,
		});
		const postParams = new FormData();
		postParams.append('email', params.email);
		postParams.append('lang_code', params.lang_code);
		postParams.append('birth_date', params.birth_date);
		postParams.append('operation_code', params.operation_code);

		return this.httpClient
			.post<any>(routes.passReset(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}

	unlockAccount(params: unlockAccParams, token: string, recaptcha: string) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
			Recaptcha: recaptcha,
		});

		const postParams = new FormData();
		postParams.append('email', params.email);
		postParams.append('birth_date', params.birth_date);
		postParams.append('lang_code', params.lang_code);
		return this.httpClient
			.post<any>(routes.unlockAcc(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}

	checkActivationCode(activationCode: string) {
		return this.httpClient.get<any>(routes.activateCode(activationCode), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of(false))
		);
	}

	renewPassword(params: passwordChangeParams, token: string) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
		});

		const postParams = new FormData();
		postParams.append('email', params.email);
		postParams.append('password', params.password);
		postParams.append('customer_id', params.customer_id);
		postParams.append('operation_code', params.operation_code);
		postParams.append('activation_code', params.activation_code);

		return this.httpClient
			.post<any>(routes.passReset(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}
}

import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { SharedUsernameService, SharedShopInfoService } from '@shared';

@Component({
	selector: 'app-shop-footer',
	templateUrl: './shop-footer.component.html',
	styleUrls: ['./shop-footer.component.scss'],
})
export class ShopFooterComponent implements OnInit {
	shopData: any;
	shopHomepage: string;
	shopSlug: string;
	constructor(
		private sharedUsernameService: SharedUsernameService,
		private sharedShopInfoService: SharedShopInfoService
	) {}

	ngOnInit(): void {}
}

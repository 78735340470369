import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { SharedUsernameService } from '@shared';
import { environment } from '@env/environment';
import { map, catchError } from 'rxjs/operators';
export interface Room {
	id: string;
	message: string;
	status: number;
}
export interface Delete {
	id: string;
}

export class SocketNameSpace extends Socket {
	constructor(socketConfig: SocketIoConfig) {
		super(socketConfig);
	}
}
@Injectable({
	providedIn: 'root',
})
export class RoomService {
	rooms;
	currentRoom;
	delete;
	clear;
	error;
	api_username: string;
	shop: SocketNameSpace;
	shopSlug: string;

	constructor(private socket: Socket, private sharedUsernameService: SharedUsernameService) {
		this.shopSlug = this.sharedUsernameService.getSlug();
		var nameSpace = '/hankyu-' + this.shopSlug;
		var url = environment.socketUrl + nameSpace;
		this.shop = new SocketNameSpace({ url: url, options: { transports: ['websocket', 'polling', 'flashsocket'] } });
		this.rooms = this.shop.fromEvent<string[]>('rooms');
		this.currentRoom = this.shop.fromEvent<Room>('room');
		this.error = this.shop.fromEvent<Room>('error');
		this.delete = this.shop.fromEvent<Delete>('delete');
		this.clear = this.shop.fromEvent<Delete>('clear');
	}

	getInRoom(id: string) {
		this.shop.emit('get', id);
	}

	makeRoom() {
		var newId = this.generateID();
		this.shop.emit('new', { shopSlug: this.shopSlug, id: newId, message: '', status: 2 });
		return newId;
	}

	communicate(param: Room) {
		this.shop.emit('communicate', param);
	}

	deleteRoom(id: string) {
		this.shop.emit('delete', id);
	}

	deleteRoomQr() {
		return this.delete;
	}

	getRooms() {
		return this.shop.fromEvent<string[]>('rooms');
	}

	private generateID() {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < 6; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}
}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedShopInfoService {
	private info = new BehaviorSubject({});
	shopInfo = this.info.asObservable();

	constructor() {}

	setShopInfo(info: any) {
		this.info.next(info);
	}
}

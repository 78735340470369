import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { CookieService } from 'ngx-cookie-service';
//import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';
//const credentialsKey = 'hitokoma_auth_token';
const routes = {
	validate_token: () => `/manage/api/v1/verify-customer-token`,
};
/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
	providedIn: 'root',
})
export class CredentialsService {
	private _credentials: boolean | null = null;
	api_username: string;
	accessToken: string;
	requestOptions: any;
	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.api_username = this.sharedUsernameService.generateUsername();
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});

		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	/**
	 * Checks is the user is authenticated.
	 * @return True if the user is authenticated.
	 */
	isAuthenticated(): boolean {
		return !!this.credentials;
	}

	/**
	 * Gets the user credentials.
	 * @return The user credentials or null if the user is not authenticated.
	 */
	get credentials(): boolean | null {
		return this._credentials;
	}

	/*set credentials(val:boolean){
		this._credentials = val;
	}*/

	/**
	 * Sets the user credentials.
	 * The credentials may be persisted across sessions by setting the `remember` parameter to true.
	 * Otherwise, the credentials are only persisted for the current session.
	 * @param credentials The user credentials.
	 * @param remember True to remember credentials across sessions.
	 */
	setCredentials(val: boolean = false) {
		this._credentials = val;
	}

	validateUserToken() {
		const postParams = new FormData();
		//postParams.append('api_username', this.sharedUsernameService.generateUsername());
		return this.httpClient.post<any>(routes.validate_token(), postParams, this.requestOptions).pipe(
			map((response: any) => {
				this._credentials = response.is_success;
				return response.is_success;
			}),
			catchError(() => of(false))
		);
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	getdata: (p: categoryParams) => `/manage/api/v1/category`,
};

export interface categoryParams {
	lang_code: string;
}

@Injectable({
	providedIn: 'root',
})
export class CategoryService {
	api_username: string;
	requestOptions: any;

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getCategory(param: categoryParams) {
		return this.httpClient.get<any>(routes.getdata(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('APIエラー'))
		);
	}
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { HomeComponent } from './home.component';

/*const routes: Routes = [
	Shell.childRoutes([
		{ path: '', redirectTo: '/home', pathMatch: 'full' },
		{ path: 'home', component: HomeComponent, data: { title: _('Home') } },
	]),
];*/

const routes: Routes = [{ path: '', pathMatch: 'full', component: HomeComponent, data: { title: _('home') } }];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [],
})
export class HomeRoutingModule {}

import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { TranslateService } from '@ngx-translate/core';
export interface AlertModel {
	title?: string;
	message: string;
	btn?: string;
}

@Component({
	selector: 'alert',
	templateUrl: './common-dialog.component.html',
})
export class CommonDialogComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
	title: string;
	message: string;
	btn: string;
	btn_message: string;
	constructor(private translateService: TranslateService) {
		super();
	}

	ngOnInit(): void {
		if (this.btn != null) {
			this.btn_message = this.translateService.instant(this.btn);
		} else {
			this.btn_message = this.translateService.instant('close');
		}
	}
}

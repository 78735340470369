<div class="modal-content">
	<div class="modal-header">
		<h4>{{ title || 'Confirm' }}</h4>
	</div>
	<div class="modal-body">
		<p [innerHtml]="message"></p>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-outline-danger"
			style="background-color: #9e9e9e"
			(click)="cancel()"
			translate
		>
			no
		</button>
		<button type="button" class="btn btn-primary" (click)="confirm()" translate>yes</button>
	</div>
</div>

<main class="frontpage">
	<div *ngIf="!isClear">
		<section class="sec-main">
			<br />
			<h2 class="title">ワンタイム・QRコード 端末：{{ currentRoomID }}</h2>
			<p class="msg-errror" *ngIf="error">{{ error }}</p>
			<br />
			<div *ngIf="!isDeleted">
				<qrcode
					class="qr-container"
					*ngIf="isShowQR == true"
					[qrdata]="qrUrl"
					[errorCorrectionLevel]="'M'"
					[allowEmptyString]="true"
				></qrcode>
				<br />
				<a target="_blank" href="{{ qrUrl }}" class="link">{{ qrUrl }}</a>
				<br />
			</div>
		</section>
		<section *ngIf="!error">
			<div>
				<button (click)="generateQrForm(currentRoomID)" class="btn cursor-pointer">QRコード生成</button
				><br /><br />
			</div>
		</section>
	</div>
	<div *ngIf="isClear">
		<h2 style="text-align: center">Connection lost</h2>
	</div>
</main>

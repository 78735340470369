import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpHeaders,
	HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { AuthenticationService } from './auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError, filter, take, switchMap, mergeMap, map } from 'rxjs/operators';
import { SharedAccessTokenService, LoadingService, SharedUsernameService } from '@shared';
import { RefreshTokenService, RandomTokenService } from './@api';
import { JwtHelperService, JwtInterceptor } from '@auth0/angular-jwt';
//import { ReCaptchaV3Service } from 'ng-recaptcha';
const helper = new JwtHelperService();
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	token: string;
	api_username: string;
	constructor(
		private authenticationService: AuthenticationService,
		private cookieService: CookieService,
		private sharedAccessTokenService: SharedAccessTokenService,
		private jwtInterceptor: JwtInterceptor,
		private refreshTokenService: RefreshTokenService,
		private loadingService: LoadingService,
		//private recaptchaV3Service: ReCaptchaV3Service,
		private randomTokenService: RandomTokenService,
		private sharedUsernameService: SharedUsernameService
	) {
		this.api_username = this.sharedUsernameService.generateUsername();
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.loadingService.setIsLoading(true);
		if (this.isApiUrl(request.url)) {
			this.sharedAccessTokenService.sharedAccessToken.subscribe((accessToken) => (this.token = accessToken));

			/*if (request.method == 'POST' || request.method == 'PUT') {
				this.randomTokenService.getToken().subscribe((token) => {
					this.csrf = token;
				});
				//this.recaptchaV3Service.execute('verify').subscribe((token) => (this.csrf = token));
			}*/

			if (this.refreshTokenService.tokenNeedsRefresh(this.token, request.method)) {
				return this.handleByRefreshingAccessToken(request, next);
			}

			if (this.refreshTokenService.hasToWaitForRefresh(this.token, request.method)) {
				return this.handleByWaitingForRefresh(request, next);
			}

			//request = this.addTokenToRequest(this.token, request, this.csrf);
			return this.handleCSRF(this.token, request, next);
			/*return next
				.handle(request)
				.pipe(
					catchError((err) => {
						this.loadingService.setIsLoading(false);
						return err;
					})
				)
				.pipe(
					map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
						if (evt instanceof HttpResponse) {
							this.loadingService.setIsLoading(false);
						}
						return evt;
					})
				);*/
		}

		return next
			.handle(request)
			.pipe(
				catchError((err) => {
					this.loadingService.setIsLoading(false);
					return err;
				})
			)
			.pipe(
				map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
					if (evt instanceof HttpResponse) {
						this.loadingService.setIsLoading(false);
					}
					return evt;
				})
			);
	}
	private handleByRefreshingAccessToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.refreshTokenService.refreshAccessToken().pipe(
			catchError((error: HttpErrorResponse) => {
				//console.log(error);
				if (error.status === 401 && error.error.message === 'fail refresh token') {
					return next
						.handle(request)
						.pipe(
							catchError((err) => {
								this.loadingService.setIsLoading(false);
								return err;
							})
						)
						.pipe(
							map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
								if (evt instanceof HttpResponse) {
									this.loadingService.setIsLoading(false);
								}
								return evt;
							})
						);
				}
			}),
			switchMap((newAccessToken: string) => {
				//request = this.addTokenToRequest(newAccessToken, request, csrf);
				return this.handleCSRF(newAccessToken, request, next);
				/*return next
					.handle(request)
					.pipe(
						catchError((err) => {
							this.loadingService.setIsLoading(false);
							return err;
						})
					)
					.pipe(
						map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
							if (evt instanceof HttpResponse) {
								this.loadingService.setIsLoading(false);
							}
							return evt;
						})
					);*/
			})
		);
	}

	private handleByWaitingForRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.refreshTokenService.waitForAccessTokenRefresh().pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401 && error.error.message === 'fail refresh token') {
					return next
						.handle(request)
						.pipe(
							catchError((err) => {
								this.loadingService.setIsLoading(false);
								return err;
							})
						)
						.pipe(
							map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
								if (evt instanceof HttpResponse) {
									this.loadingService.setIsLoading(false);
								}
								return evt;
							})
						);
				}
			}),
			switchMap((newAccessToken: string) => {
				//request = this.addTokenToRequest(newAccessToken, request, csrf);
				return this.handleCSRF(newAccessToken, request, next);
				/*return next
					.handle(request)
					.pipe(
						catchError((err) => {
							this.loadingService.setIsLoading(false);
							return err;
						})
					)
					.pipe(
						map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
							if (evt instanceof HttpResponse) {
								this.loadingService.setIsLoading(false);
							}
							return evt;
						})
					);*/
			})
		);
	}
	private isApiUrl(url: string): boolean {
		//console.log(url);

		const isApiUrl: boolean = url.startsWith(environment.serverUrl);
		const isSignUpUrl: boolean = url.includes('signup');
		const isShopInfo: boolean = url.includes('shop-info');
		const isProduct: boolean = url.includes('product');
		const isCategory: boolean = url.includes('category');
		const isTokenRefreshUrl: boolean = url.endsWith('refresh-customer-token');
		const isAuthUrl: boolean = url.endsWith('auth');
		const isPostal: boolean = url.includes('postal-searching');
		const isPasswordReset: boolean = url.includes('password-reset');
		const isUnlock: boolean = url.includes('unlock-customer-account');
		const isRandomToken: boolean = url.includes('random-token');
		const isContact: boolean = url.includes('inquiry');

		var isOk =
			isApiUrl &&
			!isAuthUrl &&
			!isSignUpUrl &&
			!isTokenRefreshUrl &&
			!isShopInfo &&
			!isCategory &&
			!isProduct &&
			!isUnlock &&
			!isPasswordReset &&
			!isRandomToken &&
			!isContact &&
			!isPostal;

		return isOk;
	}

	private addTokenToRequest(
		token: string,
		request: HttpRequest<any>,
		next: HttpHandler,
		csrf: string
	): Observable<HttpEvent<any>> {
		if (token != null) {
			//console.log('a-' + token);
			//const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
			if (csrf != undefined) {
				var httpHeaders = new HttpHeaders({
					Authorization: `Bearer ${token}`,
					'X-Xsrf-Token': csrf,
					'X-Api-Username': this.api_username,
				});
			} else {
				var httpHeaders = new HttpHeaders({
					Authorization: `Bearer ${token}`,
					'X-Api-Username': this.api_username,
				});
			}

			request = request.clone({ headers: httpHeaders, withCredentials: true });
		} else {
			//console.log('b-' + token);
			if (csrf != undefined) {
				var httpHeaders = new HttpHeaders({
					Authorization: `Bearer`,
					'X-Xsrf-Token': csrf,
					'X-Api-Username': this.api_username,
				});
			} else {
				const httpHeaders = new HttpHeaders({
					'X-Api-Username': this.api_username,
				});
			}

			request = request.clone({ headers: httpHeaders, withCredentials: true });
		}

		return next.handle(request);
	}

	handleCSRF(token: string, request: HttpRequest<any>, next: any) {
		return from(this.randomTokenService.interceptorGetToken()).pipe(
			switchMap((csrf) => this.addTokenToRequest(token, request, next, csrf))
		);
	}
}

import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { CategoryService } from '../@api';
import { I18nService } from '../i18n/i18n.service';
import { finalize } from 'rxjs/operators';
import { ShopHeaderService, SharedAuthService, SharedShopInfoService } from '@shared';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopInfoService, RandomTokenService } from '../@api';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { Title, Meta } from '@angular/platform-browser';
import { Logger } from '@core';
//import { ReCaptchaV3Service } from 'ng-recaptcha';
const log = new Logger('Shop header');

@Component({
	selector: 'app-shop-header',
	templateUrl: './shop-header.component.html',
	styleUrls: ['./shop-header.component.scss'],
})
export class ShopHeaderComponent implements OnInit {
	allProduct: any = [];
	treeCategories: any = [];
	isLoading = false;
	apiUsername: string;
	showNav: boolean = false;
	isLogin: boolean = false;
	shopName: string;
	shopLogo: string;
	shopBG: string;
	shopData: any = [];
	pageSlug: string;

	constructor(
		private categoryService: CategoryService,
		private i18nService: I18nService,
		private router: Router,
		private headerService: ShopHeaderService,
		private credentialsService: CredentialsService,
		private authenticationService: AuthenticationService,
		private sharedAuthService: SharedAuthService,
		private sharedShopInfoService: SharedShopInfoService,
		private shopInfoService: ShopInfoService,
		private titleService: Title,
		private meta: Meta,
		private randomTokenService: RandomTokenService
	) {}

	ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	getData: (p: productTemplateParams) => `/manage/api/v1/product-template?product_id=${p.product_id}`,
};

export interface productTemplateParams {
	product_id?: number;
}

@Injectable({
	providedIn: 'root',
})
export class ProductTemplateService {
	private customizeForm = new BehaviorSubject<any>([]);
	customizeAccess = this.customizeForm.asObservable();

	// ユーザーが編集したい時のデータ
	private dataToModify = new BehaviorSubject<any>([]);
	dataToModifyObj = this.dataToModify.asObservable();

	api_username: string;
	requestOptions: any;
	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	passCustomizeData(data: any) {
		this.customizeForm.next(data);
	}
	getTemplate(param: productTemplateParams) {
		return this.httpClient.get<any>(routes.getData(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}

	// ユーザーが編集したい時のデータ
	sendDataToModify(data: any) {
		this.dataToModify.next(data);
	}
}

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { SharedUsernameService, SharedAccessTokenService } from '@shared';
import { AESEncryptDecryptService } from '@core';
import { ShoppingCartService } from '../@api';

const routes = {
	order: () => `/manage/api/v1/order`,
	guestOrder: () => `/manage/api/v1/guest-order`,
	getOrder: (p: orderParams) => `/manage/api/v1/order?operation_code=${p.operationCode}&lang_code=${p.langCode}`,
	getOrderDetail: (p: orderDetailParams) =>
		`/manage/api/v1/order?operation_code=${p.operationCode}&lang_code=${p.langCode}&order_no=${p.orderNo}`,
};

export interface orderParams {
	operationCode: string;
	langCode: string;
}

export interface orderDetailParams {
	operationCode: string;
	langCode: string;
	orderNo: string;
}

@Injectable({
	providedIn: 'root',
})
export class OrderService {
	api_username: string;
	requestOptions: any;
	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	sendOrder(params: any, operationCode: number) {
		const postParams = new FormData();

		postParams.append('lang_code', 'ja');
		postParams.append('payment_type', params.payment_type);

		if (params?.isMember) {
			if (operationCode == 2) {
				postParams.append('operation_code', String(operationCode));
				postParams.append('address_id', params?.address?.address_id);
			} else {
				postParams.append('operation_code', String(operationCode));
				postParams.append('first_name', params?.address?.first_name);
				postParams.append('last_name', params?.address?.last_name);
				postParams.append('first_name_kana', params?.address?.first_name_kana);
				postParams.append('last_name_kana', params?.address?.last_name_kana);
				postParams.append('to_postal', params?.address?.to_postal);
				postParams.append('to_add1', params?.address?.to_add1);
				postParams.append('to_add2', params?.address?.to_add2);
				postParams.append('to_add3', params?.address?.to_add3);
				postParams.append('pref_id', params?.address?.pref_id);
				postParams.append('to_tel', params?.address?.to_tel);
			}
			return this.httpClient.post(routes.order(), postParams, this.requestOptions);
		} else {
			postParams.append('first_name', params?.address?.first_name);
			postParams.append('last_name', params?.address?.last_name);
			postParams.append('first_name_kana', params?.address?.first_name_kana);
			postParams.append('last_name_kana', params?.address?.last_name_kana);
			postParams.append('to_postal', params?.address?.to_postal);
			postParams.append('to_mail', params?.address?.email);
			postParams.append('to_add1', params?.address?.to_add1);
			postParams.append('to_add2', params?.address?.to_add2);
			postParams.append('to_add3', params?.address?.to_add3);
			postParams.append('pref_id', params?.address?.pref_id);
			postParams.append('to_tel', params?.address?.to_tel);
			return this.httpClient.post(routes.guestOrder(), postParams, this.requestOptions);
		}
	}

	getOrder(params: orderParams) {
		return this.httpClient.get<any>(routes.getOrder(params), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('APIエラー'))
		);
	}

	getOrderDetail(params: orderDetailParams) {
		return this.httpClient.get<any>(routes.getOrderDetail(params), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('APIエラー'))
		);
	}
}

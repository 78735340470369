import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';
const routes = {
	getData: () => `/manage/api/v1/random-token`,
};

@Injectable({
	providedIn: 'root',
})
export class RandomTokenService {
	api_username: string;
	requestOptions: any;
	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getToken() {
		return this.httpClient.get<any>(routes.getData(), this.requestOptions).pipe(
			map((response: any) => {
				if (response.is_success === true) {
					return response.result_data;
				}
			}),
			catchError(() => of('API通信エラー'))
		);
	}

	interceptorGetToken() {
		return this.httpClient
			.get<any>(routes.getData(), this.requestOptions)
			.toPromise()
			.then((response: any) => {
				return response.result_data;
			})
			.catch();
	}
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger, AESEncryptDecryptService } from '@core';
import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
	providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
	constructor(
		private router: Router,
		private credentialsService: CredentialsService,
		private AESEncryptDecrypt: AESEncryptDecryptService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return new Promise((res) => {
			this.credentialsService.validateUserToken().subscribe(
				(data) => {
					if (data === true) {
						res(true);
					} else {
						var encryptRedirectTo = this.AESEncryptDecrypt.encryptParam(state.url);
						//var decryptRedirectTo = this.AESEncryptDecrypt.decryptParam(encryptRedirectTo);
						//console.log(decryptRedirectTo);
						this.router.navigate(['/login'], {
							queryParams: { redirect: encryptRedirectTo },
							replaceUrl: true,
						});
						res(false);
					}
				},
				(error) => {
					var encryptRedirectTo = this.AESEncryptDecrypt.encryptParam(state.url);
					this.router.navigate(['/login'], {
						queryParams: { redirect: encryptRedirectTo },
						replaceUrl: true,
					});
					res(false);
				}
			);
		});
	}
	/*canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		console.log('1');
		if (this.credentialsService.isAuthenticated()) {
			return true;
		}

		log.debug('Not authenticated, redirecting and adding redirect url...');
		this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
		return false;
	}*/
}

import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
	//@Input() isLoading = true;

	message: string | undefined = '';
	extraMessage: string | undefined = '';
	subscription: Subscription;

	constructor(private translate: TranslateService, private loadingService: LoadingService) {
		// Set default message
		this.message = this.translate.instant('now-loading');
		// Set custom message
		this.subscription = this.loadingService.loadMsg.pipe().subscribe((x: any) => {
			if (x?.msg) {
				this.message = x?.msg;
			} else {
				this.message = this.translate.instant('now-loading');
			}
			if (x?.type == 0) {
				this.loaderUnsubscribe();
			}
		});
	}

	ngOnInit() {}

	loaderUnsubscribe() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}

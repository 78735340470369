import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class ShopHeaderService {
	private headerNav = new BehaviorSubject(false);
	showHeaderNav = this.headerNav.asObservable();

	constructor() {}

	resetHeader() {
		this.headerNav.next(false);
		window.scrollTo(0, 0);
	}
}

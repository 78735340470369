import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedUsernameService } from '@shared';

const routes = {
	refreshToken: () => `/manage/api/v1/refresh-customer-token`,
};
@Injectable()
export class SharedAccessTokenService {
	private accessToken = new BehaviorSubject('');
	sharedAccessToken = this.accessToken.asObservable();

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {}

	getAccessToken() {
		return this.accessToken.getValue();
	}

	setAccessToken(token: string) {
		this.accessToken.next(token);
	}

	public refreshToken(): Observable<string> {
		//console.log(this.getAccessToken());
		var reqHeader = new HttpHeaders({
			Authorization: 'Bearer ' + this.getAccessToken(),
		});

		const postParams = new FormData();
		postParams.append('api_username', this.sharedUsernameService.generateUsername());

		return this.httpClient.post<any>(routes.refreshToken(), postParams, {
			headers: reqHeader,
			withCredentials: true,
		});
	}
}

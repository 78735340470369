import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
	constructor(private titleService: Title, private translateService: TranslateService) {}
	ngOnInit(): void {}
	ngAfterViewChecked() {
		this.titleService.setTitle(this.translateService.instant('page-not-found'));
	}
}

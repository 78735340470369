import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
const routes = {
	getdata: (p: postalParams) =>
		`/manage/api/v1/postal-searching?postal_code=${p.postal_code}&lang_code=${p.lang_code}`,
};
export interface postalParams {
	postal_code: string;
	lang_code: string;
}
@Injectable({
	providedIn: 'root',
})
export class PostalSearchingService {
	constructor(private httpClient: HttpClient) {}

	getPostal(param: postalParams) {
		return this.httpClient.get<any>(routes.getdata(param)).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';
import { JwtHelperService, JwtInterceptor } from '@auth0/angular-jwt';
const helper = new JwtHelperService();
const routes = {
	sendData: () => `/manage/api/v1/customer-address`,
	getData: (p: addressParams) => `/manage/api/v1/customer-address?operation_code=${p.operation_code}`,
	getDefault: (p: addressParams) => `/manage/api/v1/customer-address?operation_code=${p.operation_code}&is_default=1`,
	getOneData: (p: addressParams) => `/manage/api/v1/customer-address?id=${p.id}&operation_code=${p.operation_code}`,
};

export interface addressParams {
	id?: string;
	//customer_id: string;
	operation_code: number;
}

export interface setDefaultParams {
	id: string;
	//customer_id: string;
	operation_code: number;
}

export interface saveParams {
	id: string;
	operation_code: number;
	first_name: string;
	last_name: string;
	first_name_kana: string;
	last_name_kana: string;
	to_tel: string;
	to_add1: string;
	to_add2: string;
	to_add3?: string;
	pref_id: number;
	to_postal: string;
}
@Injectable({
	providedIn: 'root',
})
export class CustomerAddressService {
	api_username: string;
	requestOptions: any;
	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getCustomerAddress(params: addressParams) {
		return this.httpClient.get<any>(routes.getData(params), this.requestOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(() => of(false))
		);
	}

	getCustomerSpecificAddress(params: addressParams) {
		return this.httpClient.get<any>(routes.getOneData(params), this.requestOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(() => of(false))
		);
	}

	getCustomerDefaultAddress(params: addressParams) {
		return this.httpClient.get<any>(routes.getDefault(params), this.requestOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(() => of(false))
		);
	}

	setAddress(params: setDefaultParams) {
		//params['api_username'] = this.sharedUsernameService.generateUsername();
		return this.httpClient.put<any>(routes.sendData(), JSON.stringify(params), this.requestOptions).pipe(
			map((response) => {
				return response;
			}),
			catchError((err) => {
				//console.log(err);
				return err;
			})
		);
	}

	updateAddress(params: saveParams) {
		//params['api_username'] = this.sharedUsernameService.generateUsername();

		return this.httpClient.put<any>(routes.sendData(), JSON.stringify(params), this.requestOptions).pipe(
			map((response) => {
				return response;
			}),
			catchError((err) => {
				//console.log(err);
				return err;
			})
		);
	}

	insertAddress(params: saveParams) {
		const postParams = new FormData();
		for (const [key, value] of Object.entries(params)) {
			postParams.append(key, value);
		}

		/*postParams.forEach((value, key) => {
			console.log(key + ' ' + value);
		});*/
		//postParams.append('api_username', this.sharedUsernameService.generateUsername());

		return this.httpClient.post<any>(routes.sendData(), postParams, this.requestOptions).pipe(
			map((response) => {
				//console.log(response);
				return response;
			}),
			catchError((err) => {
				//console.log(err);
				return err;
			})
		);
	}
}

import { Component, OnInit, OnDestroy, HostBinding, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed, AESEncryptDecryptService } from '@core';
import { I18nService } from '@app/i18n';
import {
	ShopHeaderService,
	SharedAuthService,
	SharedUsernameService,
	SharedShopInfoService,
	SharedCartItemCountService,
} from '@shared';
import { CredentialsService } from '@app/auth';

declare const gtag: Function;
//import { ReCaptchaV3Service } from 'ng-recaptcha';
const log = new Logger('App');

@UntilDestroy()
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	shopData: any = [];
	pageSlug: string;
	disableHeader: boolean = false;
	disableFooter: boolean = false;
	headerHideConditions: any = ['product-customize'];
	footerHideConditions: any = ['product-customize'];
	nocheckLogin: any = [
		'mypage',
		'mypage/profile',
		'mypage/address',
		'mypage/membership-cancel',
		'mypage/order',
		'contact',
		'basket',
		'shipping-address',
		'checkout',
		'order-successful',
		'order-failed',
		'payment-cancelled',
	];
	noback: any = ['/', '/contact/complete'];
	homeRedirectConditions: any = ['login'];
	isChecked: boolean = false;
	cartItemCount: string = '';

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private translateService: TranslateService,
		private i18nService: I18nService,
		private AESEncryptDecrypt: AESEncryptDecryptService,
		private sharedUsernameService: SharedUsernameService,
		private sharedAuthService: SharedAuthService,
		private headerService: ShopHeaderService,
		private credentialsService: CredentialsService,
		private sharedShopInfoService: SharedShopInfoService,
		private sharedCartItemCountService: SharedCartItemCountService //private recaptchaV3Service: ReCaptchaV3Service,
	) {
		this.sharedUsernameService.setUsername(this.sharedUsernameService.generateUsername());

		//this.checkUserToken();
		//call every time
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				this.headerService.resetHeader();
				this.sharedUsernameService.setUsername(this.sharedUsernameService.generateUsername());
				//this.sharedAuthService.setIsLogin(this.credentialsService.isAuthenticated());
				this.pageSlug = this.router.url;
				this.disableHeader = this.headerHideConditions.some((el: string) => this.pageSlug.includes(el));
				this.disableFooter = this.footerHideConditions.some((el: string) => this.pageSlug.includes(el));

				gtag('event', 'page_view', {
					page_path: ev.urlAfterRedirects,
				});
			}
		});
	}
	@HostListener('window:popstate', ['$event'])
	onPopState(event: any) {
		var isFound = this.noback.some((el: string) => this.pageSlug == el);
		if (isFound) {
			location.reload();
		}
	}
	ngOnInit() {
		// Setup logger
		if (environment.production) {
			Logger.enableProductionMode();
		}
		this.sharedUsernameService.setUsername(this.sharedUsernameService.generateUsername());
		//console.log(environment.defaultLanguage);
		//log.debug('app component');
		//this.getSubdomain();
		// Setup translations
		this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

		const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

		// Change page title on navigation or language change, based on route data
		merge(this.translateService.onLangChange, onNavigationEnd)
			.pipe(
				map(() => {
					let route = this.activatedRoute;
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				switchMap((route) => route.data),
				untilDestroyed(this)
			)
			.subscribe((event) => {
				const title = event.title;
				if (title) {
					this.titleService.setTitle(this.translateService.instant(title));
				}
			});

		//
		this.sharedShopInfoService.shopInfo.subscribe((data: any) => {
			this.shopData = data?.information;
			this.cssVarMain = this.shopData?.theme_color;
			if (this.shopData) {
				//color
				this.cssVarMain = this.shopData.theme['theme_color'];
				if (this.shopData['google_analytics_common'])
					this.addGAScript(this.shopData['google_analytics_common']);
			}
		});
	}

	@HostBinding('style.--main') private cssVarMain: string;

	/*getSubdomain() {
		const domain = window.location.hostname;
		var subdomain = '';
		if (
			domain.indexOf('.') < 0 ||
			domain.split('.')[0] === 'example' ||
			domain.split('.')[0] === 'lvh' ||
			domain.split('.')[0] === 'www'
		) {
			subdomain = '';
		} else {
			subdomain = domain.split('.')[0];
		}
		this.apiUsername = this.AESEncryptDecrypt.encrypt(subdomain);
		this.sharedUsernameService.setUsername(this.apiUsername);
		//console.log('subdomain', subdomain);
		//console.log('apiUsername', this.apiUsername);
	}*/
	ngOnDestroy() {
		this.i18nService.destroy();
	}

	/** Add Google Analytics Script Dynamically */
	addGAScript(script: string) {
		/*let gtagScript: HTMLScriptElement = document.createElement('script');
		gtagScript.async = true;
		gtagScript.src = script;*/

		const fragment = document.createRange().createContextualFragment(script);
		//anyElement.appendChild(fragment);
		document.head.appendChild(fragment);
		//document.head.innerHTML = script + document.head.innerHTML;

		//document.head.prepend(script);
	}
}

import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './auth/login.component';

const routes: Routes = [
	{ path: 'login', component: LoginComponent, data: { title: _('login') } },
	{ path: '**', component: PageNotFoundComponent }, // 追加
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {
	constructor(private router: Router) {
		this.router.errorHandler = (error: any) => {
			this.router.navigate(['']); // or redirect to default route
		};
	}
}

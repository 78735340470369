import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnDestroy, HostListener } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Logger } from '@core/logger.service';
import { GenerateFormService, RandomTokenService } from '../@api';
import { I18nService } from '../i18n/i18n.service';
import { Title, Meta } from '@angular/platform-browser';
import { ShopHeaderService, SharedShopInfoService, RoomService } from '@shared';
import { SharedPipeModule } from '../@shared/safeHtmlPipe.module';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SimpleModalService } from 'ngx-simple-modal';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';

import { Socket } from 'ngx-socket-io';
const log = new Logger('Home');
declare const dataLayer: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
	@Output() toggleNav = new EventEmitter<boolean>(false);

	isLoading = false;
	showLimit: number = 6;
	apiUsername: string;
	productCount: number;
	shopData: any;
	products: [];
	shopBackground: string;
	shopBackgroundTitle: string;
	postRateMsg: string;
	customHtml: any;
	customBottomBodyHtml: any;
	deviceInfo: any;
	analyticsProduct: any = [];
	captchaResponse: string;
	rooms: Observable<string[]>;
	data: Observable<string[]>;
	error: string;
	currentRoom: string;
	currentRoomID: string;
	roomCount: number;
	qrUrl: string = '';
	isShowQR = false;
	isDeleted = false;
	isClear = false;
	private _roomSub: Subscription;

	constructor(
		private generateFormService: GenerateFormService,
		private i18nService: I18nService,
		private titleService: Title,
		private headerService: ShopHeaderService,
		private sharedShopInfoService: SharedShopInfoService,
		private sanitizer: DomSanitizer,
		private meta: Meta,
		private translateService: TranslateService,
		public deviceService: DeviceDetectorService,
		private randomTokenService: RandomTokenService,
		private SimpleModalService: SimpleModalService,
		private socket: Socket,
		private roomService: RoomService
	) {
		this.deviceInfo = this.deviceService.getDeviceInfo();
	}

	ngOnInit() {
		this.rooms = this.roomService.rooms;
		this.clearRoom();
		/*this.rooms.subscribe(result => {
			this.roomCount = result.length;
		});*/
		this.makeRoom();

		this._roomSub = this.roomService.currentRoom.subscribe((room) => {
			if (room.id == this.currentRoomID && room.message != '') {
				this.qrUrl = room.message;
				this.isShowQR = true;
				this.isDeleted = false;
			} else {
				this.isShowQR = false;
				this.qrUrl = '';
			}
		});

		this.roomService.error.subscribe((room) => {
			if (room.id == this.currentRoomID && room.message != '') {
				this.error = room.message;
			} else {
				this.error = null;
			}
		});

		this.roomService.delete.subscribe((room) => {
			if (room.id == this.currentRoomID) {
				this.isDeleted = true;
			}
		});
		window.onbeforeunload = () => this.ngOnDestroy();
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander($event: any) {
		this.removeRoom(this.currentRoomID);
	}

	async ngOnDestroy() {
		await this.removeRoom(this.currentRoomID);
		this._roomSub.unsubscribe();
	}

	loadRoom(id: string) {
		this.roomService.getInRoom(id);
	}

	removeRoom(id: string) {
		this.error = '';
		this.roomService.deleteRoom(id);
	}

	makeRoom() {
		this.currentRoomID = this.roomService.makeRoom();
	}

	communicate(id: string, message: string) {
		this.roomService.communicate({ id: id, message: message, status: 1 });
	}

	deleteRoomQr() {
		this.roomService.deleteRoomQr().subscribe((room) => {
			if (room.id == this.currentRoomID) {
				this.isDeleted = true;
			}
		});
	}

	clearRoom() {
		this.roomService.clear.subscribe((room) => {
			if (room.id == this.currentRoomID) {
				this.isClear = true;
			}
		});
	}

	getcsrf(roomId: string, recaptcha: string) {
		this.randomTokenService.getToken().subscribe((token) => {
			this.dogenerateQrForm(roomId, token);
		});
	}
	generateQrForm(roomId: string) {
		this.getcsrf(roomId, this.captchaResponse);
	}

	dogenerateQrForm(roomId: string, token: string) {
		var postParams = {
			room_id: roomId,
			lang_code: this.i18nService.language,
			operation_code: '1',
		};
		this.isLoading = true;
		this.generateFormService
			.sendRequest(postParams, token)
			.pipe(
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe(
				(response) => {
					if (response?.is_success) {
						this.communicate(roomId, response.result_data);
						console.log(response);
					} else {
						this.SimpleModalService.addModal(CommonDialogComponent, {
							title: this.translateService.instant('home'),
							message: response?.message[0],
						});
					}
				},
				(error) => {}
			);
	}
}

import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpClient,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpHeaders,
} from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError, tap, filter, first } from 'rxjs/operators';
import { SharedUsernameService, SharedAccessTokenService } from '@shared';
import { I18nService } from '../i18n/i18n.service';
import { CookieService } from 'ngx-cookie-service';
import { CredentialsService } from '../auth/credentials.service';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable({
	providedIn: 'root',
})
export class RefreshTokenService {
	apiUsername: string;
	private tokenRefreshInProgress: boolean = false;
	private refreshAccessTokenSubject: Subject<string> = new BehaviorSubject<string>(null);
	constructor(
		private httpClient: HttpClient,
		private i18nService: I18nService,
		private sharedUsernameService: SharedUsernameService,
		private sharedAccessTokenService: SharedAccessTokenService,
		private cookieService: CookieService,
		private credentialsService: CredentialsService
	) {}

	public refreshAccessToken(): Observable<string> {
		return this.sharedAccessTokenService.refreshToken().pipe(
			catchError((error: any) => {
				this.credentialsService.setCredentials(false);
				return Observable.throw(error.statusText);
			}),
			map((response: any) => response.result_data.access_token),
			tap((accessToken: string) => {
				if (accessToken != null) {
					this.sharedAccessTokenService.setAccessToken(accessToken);
					this.credentialsService.setCredentials(true);
					this.tokenRefreshInProgress = false;
					this.refreshAccessTokenSubject.next(accessToken);
				}
			})
		);
	}

	public waitForAccessTokenRefresh(): Observable<string> {
		return this.refreshAccessTokenSubject.pipe(
			filter((result) => result !== null),
			first()
		);
	}

	public tokenNeedsRefresh(accessToken: string, method: string): boolean {
		if (method == 'POST' || method == 'PUT') {
			return helper.isTokenExpired(accessToken) && !this.tokenRefreshInProgress;
		} else {
			return false;
		}
		//console.log('is expired:' + helper.isTokenExpired(accessToken));
	}

	public hasToWaitForRefresh(accessToken: string, method: string): boolean {
		if (method == 'POST' || method == 'PUT') {
			return helper.isTokenExpired(accessToken) && this.tokenRefreshInProgress;
		} else {
			return false;
		}
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	getdata: (p: productParams) =>
		`/manage/api/v1/product?operation_code=${p.operation_code}&lang_code=${p.lang_code}&limit=${p.limit}&offset=${p.offset}`,
	getdetail: (p: productParams) =>
		`/manage/api/v1/product?operation_code=${p.operation_code}&lang_code=${p.lang_code}&product_id=${p.product_id}`,
	getdataByCatSlug: (p: productParams) =>
		`/manage/api/v1/product?operation_code=${p.operation_code}&lang_code=${p.lang_code}&category_slug=${p.category_slug}`,
};

export interface productParams {
	product_id?: number;
	product_code?: string;
	product_name?: string;
	limit?: number;
	offset?: number;
	order_by?: string;
	sort_type?: string;
	category_slug?: string;
	is_instock_only?: boolean;
	operation_code: number;
	lang_code: string;
}

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	api_username: string;
	requestOptions: any;

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getProduct(param: productParams) {
		return this.httpClient.get<any>(routes.getdata(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}

	getProductDetails(param: productParams) {
		return this.httpClient.get<any>(routes.getdetail(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}
	getProductByCat(param: productParams) {
		return this.httpClient.get<any>(routes.getdataByCatSlug(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}
}

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
	name: 'fun',
	production: false,
	version: env.npm_package_version,
	serverUrl: 'https://www.kousoku-offset.fun/hankyu-api',
	socketUrl: 'https://node.kousoku-offset.fun',
	defaultLanguage: 'ja',
	supportedLanguages: ['ja'],
	appID: '84B664C6-C78A-4EF1-BD2B-94BFFE3F5745',
};

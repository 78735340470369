import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';
import { I18nService } from '../i18n/i18n.service';

const routes = {
	postData: () => `/manage/api/v1/generate-qr-form`,
};

export interface formParams {
	room_id: string;
	lang_code: string;
	operation_code: string;
}

@Injectable({
	providedIn: 'root',
})
export class GenerateFormService {
	api_username: string;

	constructor(
		private httpClient: HttpClient,
		private i18nService: I18nService,
		private sharedUsernameService: SharedUsernameService
	) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
	}

	sendRequest(params: formParams, token: string) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
		});
		const postParams = new FormData();
		postParams.append('room_id', params.room_id);
		postParams.append('lang_code', this.i18nService.language);
		postParams.append('operation_code', params.operation_code);
		/*postParams.forEach((value, key) => {
			console.log(key + ' ' + value);
		});*/
		return this.httpClient
			.post<any>(routes.postData(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}
}

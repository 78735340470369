import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';
import { I18nService } from '../i18n/i18n.service';
import { CredentialsService } from '../auth/credentials.service';

const routes = {
	postData: () => `/manage/api/v1/signup`,
	getCustomer: (p: getIdParams) => `/manage/api/v1/signup?activation_code=${p.activation_code}`,
	getReference: (p: getRefParams) => `/manage/api/v1/verify-reference?reference_code=${p.reference_code}`,
};

export interface getRefParams {
	reference_code: string;
}

export interface getIdParams {
	activation_code: string;
}

export interface mailParams {
	email: string;
	password: string;
	operation_code: string;
	lang_code: string;
	reference_from: string;
}

export interface identificationParams {
	id: string;
	activation_code: string;
	password: string;
	operation_code: string;
}

export interface personalDataParams {
	id: string;
	activation_code: string;
	email: string;
	first_name: string;
	last_name: string;
	first_name_kana: string;
	last_name_kana: string;
	to_tel: string;
	to_add1: string;
	to_add2: string;
	to_add3?: string;
	pref_id: number;
	to_postal: string;
	is_get_magazine: number;
	birth_date: string;
	nickname: string;
	gender: string;
	operation_code: string;
}

@Injectable({
	providedIn: 'root',
})
export class SignUpService {
	api_username: string;
	requestOptions: any;
	private referenceCode = new BehaviorSubject(null);
	refCode = this.referenceCode.asObservable();

	constructor(
		private httpClient: HttpClient,
		private i18nService: I18nService,
		private sharedUsernameService: SharedUsernameService,
		private credentialsService: CredentialsService
	) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	setRefCode(referenceCode: string) {
		this.referenceCode.next(referenceCode);
	}

	sendVerificationEmail(
		params: mailParams,
		referenceFrom: string,
		token: string,
		recaptcha: string,
		referenceCode: string
	) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
			Recaptcha: recaptcha,
		});

		const postParams = new FormData();
		postParams.append('email', params.email);
		postParams.append('password', params.password);
		postParams.append('lang_code', this.i18nService.language);
		//		postParams.append('api_username', 	this.sharedUsernameService.generateUsername());
		postParams.append('operation_code', params.operation_code);
		postParams.append('reference_code', '');
		//postParams.append('api_username', this.apiUsername);

		if (referenceFrom != null) {
			postParams.append('reference_from', referenceFrom);
		}

		if (referenceCode != null) {
			postParams.append('reference_code', referenceCode);
		}

		/*postParams.forEach((value, key) => {
			console.log(key + ' ' + value);
		});*/

		return this.httpClient
			.post<any>(routes.postData(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}

	customerVerification(params: identificationParams, token: string) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
		});

		const postParams = new FormData();
		for (const [key, value] of Object.entries(params)) {
			postParams.append(key, value);
		}

		//postParams.append('api_username', this.sharedUsernameService.generateUsername());

		return this.httpClient
			.post<any>(routes.postData(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}

	getReferenceData(param: getRefParams) {
		return this.httpClient.get<any>(routes.getReference(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}

	getCustomerId(param: getIdParams) {
		return this.httpClient.get<any>(routes.getCustomer(param), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}

	saveCustomerPersonalData(params: personalDataParams, token: string) {
		const postParams = new FormData();
		for (const [key, value] of Object.entries(params)) {
			postParams.append(key, value);
		}
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
		});

		//postParams.append('api_username', this.sharedUsernameService.generateUsername());

		return this.httpClient
			.post<any>(routes.postData(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					if (response.is_success === true) {
						this.credentialsService.setCredentials(true);
					}
					return response;
				}),
				catchError((err) => {
					return err;
				})
			);
	}
}

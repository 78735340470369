import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedCartItemCountService {
	private _count: string | null = null;

	constructor() {}

	getCount(): string {
		return this.count;
	}

	get count(): string | null {
		return this._count;
	}

	setCount(val: string = null) {
		this._count = val;
	}
}

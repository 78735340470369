import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	getData: () => `/manage/api/v1/shop-info?operation_code=1`,
	getKey: (p: getKeyParams) => `/manage/api/v1/shop-info?operation_code=2&type=${p.type}`,
	getSender: () => `/manage/api/v1/shop-info?operation_code=3`,
};

export interface getKeyParams {
	type: number;
}

@Injectable({
	providedIn: 'root',
})
export class ShopInfoService {
	api_username: string;
	requestOptions: any;

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	getShop() {
		return this.httpClient.get<any>(routes.getData(), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}

	getShopPaymentKey(params: getKeyParams) {
		return this.httpClient.get<any>(routes.getKey(params), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}

	getSenderAddress() {
		return this.httpClient.get<any>(routes.getSender(), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('API通信エラー'))
		);
	}
}

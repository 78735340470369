import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedAuthService {
	private isLogin = new BehaviorSubject(false);
	isUserLogin = this.isLogin.asObservable();

	constructor() {}

	setIsLogin(isLogin: boolean) {
		this.isLogin.next(isLogin);
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	sendData: () => `/manage/api/v1/shopping-cart`,
	getData: (p: cartParams) => `/manage/api/v1/shopping-cart?operation_code=${p.operation_code}`,
};

export interface cartParams {
	operation_code: number;
}

export interface updateItemParams {
	item_id: number;
	quantity?: number;
	customize_data?: string;
	operation_code: number;
}

export interface removeItemParams {
	item_id: number;
	operation_code: number;
}

export interface addNewItemParams {
	product_id: number;
	operation_code: number;
	customize_data: string;
}

@Injectable({
	providedIn: 'root',
})
export class ShoppingCartService {
	private shoppingForm = new BehaviorSubject<any>([]);
	shoppingFormInputValue = this.shoppingForm.asObservable();
	api_username: string;
	requestOptions: any;

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	passShoppingData(data: any) {
		this.shoppingForm.next(data);
	}

	getCart(params: cartParams) {
		return this.httpClient.get<any>(routes.getData(params), this.requestOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				console.log(err);
				return err;
			})
		);
	}

	updateCart(params: updateItemParams) {
		return this.httpClient.put<any>(routes.sendData(), JSON.stringify(params), this.requestOptions).pipe(
			map((response) => {
				return response;
			}),
			catchError((err) => {
				console.log(err);
				return err;
			})
		);
	}

	removeCartItem(params: removeItemParams) {
		return this.httpClient.put<any>(routes.sendData(), JSON.stringify(params), this.requestOptions).pipe(
			map((response) => {
				return response;
			}),
			catchError((err) => {
				console.log(err);
				return err;
			})
		);
	}

	addNewCartItem(params: addNewItemParams) {
		const postParams = new FormData();
		for (const [key, value] of Object.entries(params)) {
			postParams.append(key, value);
		}
		//postParams.append('api_username', this.sharedUsernameService.generateUsername());

		return this.httpClient.post<any>(routes.sendData(), postParams, this.requestOptions).pipe(
			map((response) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}
}

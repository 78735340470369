import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from './credentials.service';
import { environment } from '@env/environment';
import { SharedUsernameService, SharedAccessTokenService } from '@shared';
import { CookieService } from 'ngx-cookie-service';
import { AESEncryptDecryptService } from '@core';
import { ShoppingCartService } from '../@api';

const routes = {
	get_token: (api_username: string) => `/manage/api/v1/auth?api_username=${api_username}&operation_code=9`,
	authentication: () => `/manage/api/v1/auth`,
	validate_token: (api_username: string) => `/manage/api/v1/auth?api_username=${api_username}&operation_code=1`,
	refreshToken: (api_username: string) => `/manage/api/v1/refresh-customer-token?api_username=${api_username}`,
	logout: () => `/manage/api/v1/logout`,
};

export interface LoginInterface {
	username: string;
	password: string;
	isRemember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	api_username: string;
	accessToken: string;
	requestOptions: any;

	constructor(
		private credentialsService: CredentialsService,
		private httpClient: HttpClient,
		private cookieService: CookieService,
		private sharedUsernameService: SharedUsernameService,
		private shoppingCartService: ShoppingCartService,
		private sharedAccessTokenService: SharedAccessTokenService,
		private AESEncryptDecrypt: AESEncryptDecryptService
	) {
		this.api_username = this.sharedUsernameService.generateUsername();
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});

		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	/**
	 * Authenticates the user.
	 * @params context The login parameters.
	 * @return api response.
	 */
	login(params: LoginInterface, langCode: string, isPromoCart: boolean = false, token: string, recaptcha: string) {
		var reqHeader = new HttpHeaders({
			'X-Xsrf-Token': token,
			'X-Api-Username': this.api_username,
			Recaptcha: recaptcha,
		});

		let isRemember = params.isRemember;
		const postParams = new FormData();
		postParams.append('username', params.username);
		postParams.append('password', params.password);
		postParams.append('operation_code', '1');
		postParams.append('lang_code', langCode);

		if (isPromoCart == true) {
			postParams.append('is_promote_cart', '1');
		}

		/*postParams.forEach((value, key) => {
			console.log(key + ' ' + value);
		});*/
		return this.httpClient
			.post<any>(routes.authentication(), postParams, {
				headers: reqHeader,
				withCredentials: true,
			})
			.pipe(
				map((response) => {
					if (response.is_success === true) {
						this.sharedAccessTokenService.setAccessToken(response.result_data.access_token);
						this.credentialsService.setCredentials(true);
						localStorage.removeItem('isShowGuide');
						if (isRemember == true) {
							localStorage.setItem(
								'rememberMe',
								this.AESEncryptDecrypt.encryptRemeberMe(params.username)
							);
						} else {
							localStorage.removeItem('rememberMe');
						}
					}
					return response;
				})
			);
	}
	getAccessToken() {
		this.sharedAccessTokenService.sharedAccessToken.subscribe((accessToken) => (this.accessToken = accessToken));
		return this.accessToken;
	}

	refresh() {
		const requestOptions = {
			withCredentials: true,
		};
		const refreshObservable = this.httpClient.get<any>(
			routes.refreshToken(this.sharedUsernameService.generateUsername()),
			requestOptions
		);
		const refreshSubject = new ReplaySubject<any>(1);
		refreshSubject.subscribe(
			(response: any) => {
				if (response.is_success) {
					this.sharedAccessTokenService.setAccessToken(response.result_data.access_token);
					this.credentialsService.setCredentials(true);
				}
			},
			(err) => {
				return false;
			}
		);

		refreshObservable.subscribe(refreshSubject);
		return refreshSubject;
	}

	/**
	 * Logs out the user and clear credentials.
	 * @return True if the user was logged out successfully.
	 */
	logout(token: string) {
		const postParams = new FormData();
		return this.httpClient.post<any>(routes.logout(), postParams, this.requestOptions).pipe(
			map((response: any) => {
				if (response.is_success === true) {
					this.sharedAccessTokenService.setAccessToken(null);
					this.credentialsService.setCredentials(false);
					this.shoppingCartService.passShoppingData({});
					localStorage.removeItem('isShowGuide');
				}
				return response;
			})
		);
	}
}

import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
	recaptchaV2SiteKey: string = '6LdarPIbAAAAAPQskuTpJ_rrYqOHs2or4Y9PXyjZ';
	genderList: string =
		'[{"id":1,"name":"男性","slug":"male"},{"id":2,"name":"女性","slug":"female"},{"id":3,"name":"その他","slug":"other"}]';
	jobsList: string =
		'[{"id":1,"name":"公務員"},{"id":2,"name":"コンサルタント"},{"id":3,"name":"コンピューター関連技術職"},{"id":4,"name":"コンピューター関連以外の技術職"},{"id":5,"name":"金融関係"},{"id":6,"name":"医師"},{"id":7,"name":"弁護士"},{"id":8,"name":"総務・人事・事務"},{"id":9,"name":"営業・販売"},{"id":10,"name":"研究・開発"},{"id":11,"name":"広報・宣伝"},{"id":12,"name":"企画・マーケティング"},{"id":13,"name":"デザイン関係"},{"id":14,"name":"会社経営・役員"},{"id":15,"name":"出版・マスコミ関係"},{"id":16,"name":"学生・フリーター"},{"id":17,"name":"主婦"},{"id":18,"name":"その他"}]';
	japanPrefList: string =
		'{"prefectures":[{"id":1,"name":"北海道"},{"id":2,"name":"青森県"},{"id":3,"name":"岩手県"},{"id":4,"name":"宮城県"},{"id":5,"name":"秋田県"},{"id":6,"name":"山形県"},{"id":7,"name":"福島県"},{"id":8,"name":"茨城県"},{"id":9,"name":"栃木県"},{"id":10,"name":"群馬県"},{"id":11,"name":"埼玉県"},{"id":12,"name":"千葉県"},{"id":13,"name":"東京都"},{"id":14,"name":"神奈川県"},{"id":15,"name":"新潟県"},{"id":16,"name":"富山県"},{"id":17,"name":"石川県"},{"id":18,"name":"福井県"},{"id":19,"name":"山梨県"},{"id":20,"name":"長野県"},{"id":21,"name":"岐阜県"},{"id":22,"name":"静岡県"},{"id":23,"name":"愛知県"},{"id":24,"name":"三重県"},{"id":25,"name":"滋賀県"},{"id":26,"name":"京都府"},{"id":27,"name":"大阪府"},{"id":28,"name":"兵庫県"},{"id":29,"name":"奈良県"},{"id":30,"name":"和歌山県"},{"id":31,"name":"鳥取県"},{"id":32,"name":"島根県"},{"id":33,"name":"岡山県"},{"id":34,"name":"広島県"},{"id":35,"name":"山口県"},{"id":36,"name":"徳島県"},{"id":37,"name":"香川県"},{"id":38,"name":"愛媛県"},{"id":39,"name":"高知県"},{"id":40,"name":"福岡県"},{"id":41,"name":"佐賀県"},{"id":42,"name":"長崎県"},{"id":43,"name":"熊本県"},{"id":44,"name":"大分県"},{"id":45,"name":"宮崎県"},{"id":46,"name":"鹿児島県"},{"id":47,"name":"沖縄県"}]}';
}

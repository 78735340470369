import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { SharedUsernameService } from '@shared';

const routes = {
	checkout: () => `/manage/api/v1/payment`,
	checkSession: (p: checkSessionParams) => `/manage/api/v1/payment?type=${p.type}&operation_code=${p.operation_code}`,
};

export interface checkSessionParams {
	type: string;
	//session: string;
	operation_code: number;
}

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	api_username: string;
	requestOptions: any;

	constructor(private httpClient: HttpClient, private sharedUsernameService: SharedUsernameService) {
		this.sharedUsernameService.sharedUsername.subscribe((apiUsername) => (this.api_username = apiUsername));
		var reqHeader = new HttpHeaders({
			'X-Api-Username': this.api_username,
		});
		this.requestOptions = {
			headers: reqHeader,
			withCredentials: true,
		};
	}

	stripeCheckout(params: any) {
		const postParams = new FormData();
		postParams.append('operation_code', params.operation_code);
		//postParams.append('api_username', this.apiUsername);
		postParams.append('type', params.type);
		postParams.append('meta_data', params.meta_data);
		if (params.email) {
			postParams.append('email', params.email);
		}
		return this.httpClient.post(routes.checkout(), postParams, this.requestOptions);
	}

	checkStripeSessionId(params: checkSessionParams) {
		return this.httpClient.get<any>(routes.checkSession(params), this.requestOptions).pipe(
			map((response: any) => response),
			catchError(() => of('APIエラー'))
		);
	}
}

<app-loader *ngIf="isLoading && isClick"></app-loader>
<main class="login">
	<section class="sec-login" *ngIf="!authenticated">
		<div class="inner login-box">
			<div class="ly-login">
				<div class="bl-title">
					<p class="el-title">会員のお客様</p>
				</div>
				<div class="bl-form login-form">
					<form (ngSubmit)="submitForm()" [formGroup]="loginForm" novalidate>
						<dl>
							<dt><p class="el-name" translate>email</p></dt>
							<dd>
								<input
									type="text"
									name="username"
									[ngClass]="
										loginForm.controls.username.valid || loginForm.controls.username.untouched
											? ''
											: 'is-danger'
									"
									class="el-textbox"
									formControlName="username"
									autocomplete="email"
								/>
							</dd>
						</dl>
						<dl>
							<dt><p class="el-name" translate>password</p></dt>
							<dd class="login-pass">
								<input
									[type]="isShowPass ? 'text' : 'password'"
									name="password"
									[ngClass]="
										loginForm.controls.password.valid || loginForm.controls.password.untouched
											? ''
											: 'is-danger'
									"
									class="el-textbox"
									formControlName="password"
									autocomplete="current-password"
								/>
								<span (click)="togglePassword()" class="icon is-small is-right">
									<i
										class="clickable-icon"
										[ngClass]="isShowPass == true ? 'fas fa-eye' : 'fas fa-eye-slash'"
									></i>
								</span>
							</dd>
						</dl>
						<label class="checkbox" style="display: block; margin: 5px auto">
							<input
								type="checkbox"
								class="form-check-input"
								formControlName="isRemember"
								[checked]="isRemember"
								value="true"
							/>
							<span translate>remember-me</span>
						</label>
						<re-captcha
							(resolved)="resolved($event)"
							formControlName="recaptcha"
							siteKey="{{ recaptchaV2SiteKey }}"
						>
						</re-captcha>
						<button class="el-submit" type="submit" [disabled]="loginForm.invalid || isLoading">
							<span translate>login</span> <i class="fas fa-sign-in-alt"></i>
						</button>
					</form>
					<span class="alert" *ngIf="isLoginFail" translate>username-or-password-incorrect.</span>
					<div class="user-help">
						<a [routerLink]="['/password-reset']">
							<span translate>forgot-password</span>
						</a>
					</div>
				</div>
			</div>
			<div class="ly-signup">
				<div class="bl-title">
					<p class="el-title">はじめてのお客様</p>
				</div>
				<div class="bl-btn">
					<a [routerLink]="['/sign-up']" class="el-btn btn01" translate>sign-up</a>
					<p class="el-text" translate>you can save the shipping address purchase information</p>
				</div>
			</div>
		</div>
	</section>

	<div class="alr-logged" *ngIf="authenticated && !isLoading">
		<p translate>already logged in, would you like to log out and switch to another account?</p>
		<a (click)="logout()" class="el-btn cursor-pointer" translate>logout</a>
	</div>
</main>

<div class="modal-content">
	<div class="modal-header">
		<h4>{{ title }}</h4>
	</div>
	<div class="modal-body">
		<p [innerHtml]="message"></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="close()">{{ btn_message }}</button>
	</div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AESEncryptDecryptService } from '@core';

@Injectable()
export class SharedUsernameService {
	private apiUsername = new BehaviorSubject('');
	private shopSlug = new BehaviorSubject('');
	sharedUsername = this.apiUsername.asObservable();
	slug = this.shopSlug.asObservable();

	constructor(private AESEncryptDecrypt: AESEncryptDecryptService) {}

	generateUsername() {
		const domain = window.location.hostname;
		var subdomain = '';
		if (
			domain.indexOf('.') < 0 ||
			domain.split('.')[0] === 'example' ||
			domain.split('.')[0] === 'lvh' ||
			domain.split('.')[0] === 'www'
		) {
			subdomain = '';
		} else {
			subdomain = domain.split('.')[0];
		}
		return this.AESEncryptDecrypt.encrypt("sales");
	}
	setUsername(apiUsername: string) {
		this.apiUsername.next(apiUsername);
	}

	getSlug() {
		const domain = window.location.hostname;
		var subdomain = '';
		if (
			domain.indexOf('.') < 0 ||
			domain.split('.')[0] === 'example' ||
			domain.split('.')[0] === 'lvh' ||
			domain.split('.')[0] === 'www'
		) {
			subdomain = '';
		} else {
			subdomain = domain.split('.')[0];
		}

		//return subdomain;
		return "sales";
	}
}
